<div
  class="container-fluid"
  style="height: 100vh; overflow: hidden; background-color: #c3c3c3"
>
  <div class="row" style="height: 70px">
    <app-header style="width: 100%"></app-header>
  </div>
  <div class="row home">
    <div class="container-form">
      <div class="linha-header">
        <div class="row">
          <div class="col-md-12 titulo-painel">
            <h3>Produtos</h3>
            <div
              *ngIf="ativaLoading"
              class="spinner-border text-light loading"
              role="status"
            >
              <span class="sr-only">Carregando...</span>
            </div>
          </div>
        </div>
        <form (submit)="reload()" class="row">
          <div class="form-group col-md-3 periodo">
            <label for="input_pesquisa">Pesquisa:</label>
            <input
              id="input_pesquisa"
              [(ngModel)]="pesquisa"
              name="pesquisa"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3 periodo">
            <label for="input_empresa">Empresa:</label>
            <ng-select
              id="input_empresa"
              [items]="listaEmpresas"
              name="empresa"
              [(ngModel)]="empresaSelecionada"
              bindLabel="nome_empresa"
              bindValue="id_empresa"
              placeholder="Todos"
            >
            </ng-select>
          </div>
          <div class="form-group col-md-3 periodo">
            <label for="input_vendedor">Vendedor:</label>
            <ng-select
              id="input_vendedor"
              [items]="listaVendedoresFiltrados"
              name="vendedor"
              [(ngModel)]="vendedorSelecionado"
              bindLabel="nome_vendedor"
              bindValue="id_vendedor"
              placeholder="Todos"
            >
            </ng-select>
          </div>
          <div class="form-group col-md-2 periodo">
            <label for="input_status">Status:</label>
            <ng-select
              id="input_status"
              [items]="[
                { label: 'Ativo', value: 'A' },
                { label: 'Inativo', value: 'I' },
                { label: 'Manual', value: 'M' }
              ]"
              name="status"
              [(ngModel)]="statusSelecionado"
              bindValue="value"
              placeholder="Qualquer"
            >
            </ng-select>
          </div>
          <div class="col button-pesquisa">
            <button class="btn btn-success bg-gradient" type="submit">
              <fa-icon [icon]="faRotateRight"></fa-icon>
            </button>
          </div>
          <hr />
        </form>
      </div>
      <div class="tabela-dados table-responsive">
        <table
          datatable
          [dtOptions]="dtOptionsMercado"
          class="row-border hover"
        >
          <thead>
            <tr>
              <th>Produto</th>
              <th>ID Distribuidor</th>
              <th>ID Cliente</th>
              <th>Empresa</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="ModalChangeProduto">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <div class="col-md-12">
            <h5 class="modal-title">Alterar produto</h5>
          </div>
          <div class="col-md-12">
            <div>
              {{ produtoSelecionado && produtoSelecionado.descricao_produto }}
            </div>
          </div>
        </div>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="form-group col-md-12 periodo">
            <label for="inputFornecedor">Vendedor:</label>
            <ng-select
              [items]="listaVendedores"
              name="vendedor"
              [(ngModel)]="produtoVendedor"
              id="input_vendedor"
              bindLabel="nome_vendedor"
              bindValue="id_vendedor"
              placeholder="Nenhum"
            >
            </ng-select>
          </div>
          <div class="form-group col-md-12 periodo">
            <label for="inputFornecedor">Tipo de carga:</label>
            <ng-select
              [items]="[{ nome: 'SECA' }, { nome: 'FRIA' }]"
              name="tipo_carga"
              [(ngModel)]="produtoTipoCarga"
              placeholder="Nenhum"
              bindLabel="nome"
              bindValue="nome"
            >
            </ng-select>
          </div>

          <div class="form-group col-md-12 periodo">
            <label for="inputPesquisa">Multiplo de venda:</label>
            <input
              [(ngModel)]="produtoMultiploVenda"
              name="multiplo"
              type="number"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-12 periodo">
            <label for="inputPesquisa">Conversão Pedido:</label>
            <input
              [(ngModel)]="produtoConversaoPedido"
              name="conversao"
              type="number"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveProduct()">
          Salvar
        </button>
        <button
          type="button"
          class="btn btn-danger"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>
