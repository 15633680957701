import { Injectable } from '@angular/core';

import { User } from '../../../models/user.model';
import { ApiConfigService } from 'src/app/sevice/api-config.service';
import { Encryption } from 'src/app/util/encryption';

type ProductDTO = {
  codigo_produto_fornecedor: string;
  id_empresa: string;
  id_filial_loja: string;
  id_filial_loja_empresa: string;
  id_vendedor: string;
  multiplo_de_venda: number;
  tipo_carga: 'seca' | 'fria';
  conversao_pedido: number;
}

export type Product = {
  codigo_produto_fornecedor: string;
  descricao_produto: string;
  ean_produto: string;
  nome_vendedor: string;
  id_vendedor: string;
  multiplo_de_venda: number;
  tipo_carga: 'seca' | 'fria';
  conversao_pedido: number;
  id_empresa: string;
  id_filial_loja: string;
  id_filial_loja_empresa: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProdutosService {

  infoUser:User;

  constructor(
    private configApi: ApiConfigService,
    private encryptionApi: Encryption
  ) { }

  listaOpcoes(){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    const data = {
      id_cliente: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_usuario: this.infoUser.id_usuario
    }

    return this.configApi.configServEtl.post<string, any>('get_info_geral_filtros',data,).then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }

  listaEmpresas(){
    return this.configApi.configServEtl.get<string, any>('/fornecedor/empresas').then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }

  listaProdutos({
    vendedor,
    status,
    empresa,
    pesquisa,
    page,
  }: {
    vendedor?: string,
    status?: string,
    empresa?: string,
    pesquisa?: string,
    page?: number,
  } = {}){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    return this.configApi.configServEtl.get<Product[], any>('/fornecedor/produtos_by_filial', {
      params: {
        cnpj_fornecedor: '73909566000104',
        id_vendedor: vendedor,
        id_empresa: empresa,
        status: status,
        search: pesquisa,
        page: page,
      }
    }).then( response => {
      return response.data;
    }).catch( error =>{
      console.log(error);
    })
  }

  setProduto({
    data,
  }: {
    data: Partial<ProductDTO>
  }){
    return this.configApi.configServEtl.post<any>(`/fornecedor/produtos/${data.codigo_produto_fornecedor}?cnpj_fornecedor=73909566000104`,data,).then( response => {
      return response.data.mensagem;
    }).catch( error =>{
      console.log(error);
    })
  }

  listaVendedores(){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    return this.configApi.configServEtl.get<string, any>('/fornecedor/vendedores?cnpj_fornecedor=73909566000104').then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }
}
