<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #C3C3C3; ">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="row home">
        <div class="container-form">
            <div class="linha-header">
                <div class="row">
                    <div class="col-md-12 titulo-painel">
                        <h3>Lista Pedidos</h3>
                        <div *ngIf="ativaLoading" class="spinner-border text-light loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-2 periodo">
                        <label for="inputEmpresa">Empresa:</label>
                        <ng-select
                            [ngModel]="empresaSelecionada"
                            (change)="onEmpresaChange($event)"
                            id="input_empresa"
                            [items]="listaEmpresas"
                            bindLabel="nome_empresa"
                            bindValue="id_empresa"
                        ></ng-select>
                    </div>
                    <div class="form-group col-md-2 periodo" *ngIf="listaVendedoresFiltrados.length > 1">
                        <label for="inputFornecedor">Vendedor:</label>
                        <ng-select
                            [items]="listaVendedoresFiltrados"
                            [(ngModel)]="vendedorSelecionado"
                            id="input_vendedor"
                            bindLabel="nome_vendedor"
                            bindValue="id_vendedor"
                            placeholder="Todos"
                        >
                        </ng-select>
                    </div>
                    <div class="form-group col-md-1 periodo">
                        <label for="inputStatus">Status:</label>
                        <select [(ngModel)]="statusSelecionado" id="input_status" class="form-control" aria-label="Default select example">
                            <option *ngFor="let statusItem of listaStatus" value="{{statusItem.id}}">{{statusItem.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1 periodo">
                        <label for="inputStatus">Origem:</label>
                        <select [(ngModel)]="origemSelecionado" id="input_tipo" class="form-control" aria-label="Default select example" (change)="filtraDados('origem')">
                            <option *ngFor="let oriItem of listaOrigem" value="{{oriItem.id}}">{{oriItem.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputDataInical">Data início:</label>
                        <input [(ngModel)]="dataInicial" name="dataInicial" type="date" class="form-control" id="inputDataInical">
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputFim">Data fim:</label>
                        <input [(ngModel)]="dataFinal" name="dataFinal" type="date" class="form-control" id="inputDataFinal">
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputPesquisa">Pesquisa:</label>
                        <input [(ngModel)]="pesquisa" name="pesquisa" type="text" class="form-control" id="inputPesquisa" (keyup)="filtraDados('')">
                    </div>
                    <div class="form-group col-md-8 periodo">
                        <label for="inputEmpresa">Lojas:</label>
                        <ng-select
                            [multiple]="true"
                            [(ngModel)]="filiaisSelecionadas"
                            id="input_filiais"
                            [closeOnSelect]="false"
                        >
                            <ng-option
                                *ngFor="let itemFilial of listaFiliaisAtivas"
                                value="{{itemFilial.id_cliente+'_'+itemFilial.id_empresa+'_'+itemFilial.id_loja_empresa}}"
                            >
                                {{itemFilial.id_loja_empresa +' - '+ itemFilial.descricao}}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col button-pesquisa">
                        <button class="btn btn-success bg-gradient" type="submit" (click)="atualizaPesquisa()">
                            <fa-icon [icon]="faRotateRight"></fa-icon>
                        </button>
                        <button class="btn btn-primary bg-gradient" type="submit" (click)="visualizaPedido()">Visualizar</button>
                        <button
                            class="btn btn-secondary bg-gradient"
                            type="submit"
                            (click)="aprovaPedido()"
                            [ngClass]="{'btn-danger': idSelecionado && pedidoDivergente(idSelecionado)}"
                            *ngIf="possivelAlterarStatus(idSelecionado)"
                        >
                            Aprovar
                        </button>
                        <button
                            class="btn btn-secondary bg-gradient"
                            type="submit"
                            (click)="reprovaPedido()"
                            *ngIf="possivelAlterarStatus(idSelecionado)"
                        >
                            Reprovar
                        </button>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="tabela-dados table-responsive">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th (click)="ordernar('status_desc')">
                                STATUS
                                <span *ngIf="key=='status_desc'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('tipo_carga')">
                                TIPO CARGA
                                <span *ngIf="key=='tipo_carga'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('id_vendedor')">
                                ID VENDEDOR
                                <span *ngIf="key=='id_vendedor'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('nome_vendedor')">
                                NOME VENDEDOR
                                <span *ngIf="key=='nome_vendedor'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 100px;" (click)="ordernar('data_pedido_sem_formato')">
                                DATA
                                <span *ngIf="key=='data_pedido_sem_formato'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 100px;" (click)="ordernar('data_confirma')">
                                DATA CONFIRMADO
                                <span *ngIf="key=='data_confirma'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 150px;" (click)="ordernar('id_filial_loja')">
                                LOJA
                                <span *ngIf="key=='id_filial_loja'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 200px;" (click)="ordernar('total_pedido')">
                                TOTAL PEDIDO
                                <span *ngIf="key=='total_pedido'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 200px;" (click)="ordernar('numero_pedido_origem')">
                                CÓD. ORIGEM
                                <span *ngIf="key=='numero_pedido_origem'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listaPedidos | orderBy: order : key"
                        (click)="selecionaLinha(item.id_pedido)"
                        (dblclick)="cardDoubleClick(item.id_pedido)"
                        id="{{item.id_pedido}}"
                        [ngClass]="{ 'linhaSelecionada': item.id_pedido == idSelecionado, 'divergente': pedidoDivergente(item.id_pedido) }">
                            <td>{{item.status_desc}}</td>
                            <td>{{item.tipo_carga}}</td>
                            <td>{{item.id_vendedor}}</td>
                            <td>{{item.nome_vendedor}}</td>
                            <td style="text-align: center">{{item.data_pedido}}</td>
                            <td style="text-align: center">{{item.data_confirma}}</td>
                            <td>{{item.filial}}</td>
                            <td>{{item.total_pedido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</td>
                            <td>{{item.numero_pedido_origem}}</td>
                        </tr>
                    </tbody>
                    <tfoot style="--bs-table-bg: #6E7D81; --bs-table-color: #fff">
                        <tr style="--bs-table-bg: #6E7D81; --bs-table-color: #fff">
                            <td colspan="7">Qtd. Pedidos: {{listaPedidos.length}}</td>
                            <td>{{valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>


