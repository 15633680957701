import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { FormatDate } from 'src/app/util/format-date';
import { AprovaPedidosCheckmeService } from './service/aprova-pedidos-checkme.service';
import Swal from 'sweetalert2';
import { IItens, IPedido } from '../../models/IPedidos';
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';

declare let $: any;

type IDatasPesq = {
  dataDe: string;
  dataAte: string;
}

type ICondicoesPagamento = {
  cnpj_fornecedor_parceiro: string;
  codplpag: number;
  descricao: string;
  is_negociado: boolean;
  numdias: number;
  vlminpedido: string;
}

type IEmpresa = {
  descricao: string;
  id_loja_empresa: string;
}

@Component({
  selector: 'app-aprova-pedidos-checkme',
  templateUrl: './aprova-pedidos-checkme.component.html',
  styleUrls: ['./aprova-pedidos-checkme.component.css']
})
export class AprovaPedidosCheckmeComponent implements OnInit {

  Toast: any = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  Modal: any = Swal.mixin({
    confirmButtonText: 'Ok',
  })

  idPedido: string;

  dataInicial: string;
  dataFinal: string;
  datasAtuais: IDatasPesq;
  ativaLoading: number = 0;
  key: string = 'data_pedido';
  order: string = 'desc';
  matMenuTimer: any;

  input$ = new Subject<string>();
  loading = false;
  termoEscrito = '';

  idSelecionado: string = '';
  idSelecionadoQuant: string = '';
  idSelecionadoPreco: string = '';
  quantidadeTotal: number = 0;
  valorPedidoTotal: number = 0;
  quantidadeItens: number = 0;
  valorPedidoTotalStr: string;
  listaEmpresas: IEmpresa[] = [];
  empresaPedido: string = '';

  condicoesPagamento: ICondicoesPagamento[] = [];

  pedido: IPedido = {} as IPedido;

  bufferSize = 50;

  faAnglesLeft = faAnglesLeft;

  constructor(
    private apiAprovaPed: AprovaPedidosCheckmeService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.idPedido = params.idPedido;
      }
      );

    this.carregaDados();

    this.ativaLoading++;
    this.apiAprovaPed.ListaPlanosPagamento().then(result => {
      this.condicoesPagamento = result.sort((a, b) => {
        if (a.codplpag < b.codplpag) return -1;
        if (a.codplpag > b.codplpag) return 1;
        return 0;
      });
    }).finally(() => {
      this.ativaLoading--;
    });
  }

  montarStringDeErro(): string {
    switch (true) {
      case this.precoDivergente() && this.quantidadeDivergente():
        return 'Há itens com preço de venda menor que o preço de tabela e com quantidade divergente do múltiplo de venda.';
      case this.precoDivergente():
        return 'Há itens com preço de venda menor que o preço de tabela.';
      case this.quantidadeDivergente():
        return 'Há itens com quantidade divergente do múltiplo de venda.';
      default:
        return '';
    }
  }

  async carregaDados() {
    this.ativaLoading += 2;
    this.quantidadeTotal = 0;
    this.valorPedidoTotal = 0;
    this.quantidadeItens = 0;

    const promise1 = this.apiAprovaPed.ListaItemPedido(this.idPedido).then(result => {
      this.pedido = result[0];
      this.pedido.itens.map(e => {
        this.quantidadeTotal += e.quant_pedido_confirmada;
        this.valorPedidoTotal += e.quant_pedido_confirmada * e.preco_venda_confirmada;
        this.quantidadeItens++;
      });
      this.valorPedidoTotalStr = this.valorPedidoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

      if (this.pedidoDivergente()) {
        this.Modal.fire({
          icon: 'warning',
          title: this.montarStringDeErro()
        });
      }
    }).finally(() => {
      this.ativaLoading -= 1;
    })

    const promise2 = this.apiAprovaPed.listaOpcoes().then(result => {
      this.listaEmpresas = result.empresas
    }).finally(() => {
      this.ativaLoading -= 1;
    });

    await Promise.all([promise1, promise2]);

    this.empresaPedido = this.listaEmpresas.find(e => e.id_loja_empresa === this.pedido.id_filial_loja)?.descricao;
  }

  voltar() {
    this.router.navigate(['/listaPedidosFornecedor']);
  }

  selecionaLinha(id: string) {
    this.idSelecionado = id;
  }

  async aprovaPedido() {
    const pedido = this.pedido;

    if (!pedido) {
      return;
    }

    if (this.pedidoDivergente()) {
      this.Toast.fire({
        icon: 'warning',
        title: this.montarStringDeErro()
      });
      return;
    }

    if (pedido.status_pedido == 'A' || pedido.status_pedido == 'R') {
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi ' + (pedido.status_pedido == 'A' ? 'Aprovado!' : 'Reprovado!')
      });
      return
    }

    await this.salvarPedido();

    this.ativaLoading++;
    this.apiAprovaPed.setPedidosStatus(pedido.id_pedido, 'A').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      }).then(r => {
        this.voltar();
      }).finally(() => {
        this.ativaLoading--;
      });
    });
  }

  reprovaPedido() {
    let pedido = this.pedido;
    if (pedido.status_pedido == 'A' || pedido.status_pedido == 'R') {
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi ' + (pedido.status_pedido == 'A' ? 'Aprovado!' : 'Reprovado!')
      });
      return
    }

    this.ativaLoading++;
    this.apiAprovaPed.setPedidosStatus(pedido.id_pedido, 'R').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      }).then((r) => this.voltar()).finally(() => {
        this.ativaLoading--;
      });
    });
  }

  ordernar(key) {
    if (key == this.key)
      this.order = this.order == 'asc' ? 'desc' : 'asc';
    else {
      this.order = 'asc';
    }
    this.key = key;
  }

  cardDoubleClick(id: string, tipo): void {
    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;

    if (!this.possivelAlterarPedido()) {
      return;
    }

    if (tipo == 'quant') {
      this.idSelecionadoPreco = '';
      this.idSelecionadoQuant = id;
      setTimeout(() => { $('#input_' + this.idSelecionadoQuant).focus().select(); }, 400)
    }

    if (tipo == 'preco') {
      this.idSelecionadoPreco = id;
      this.idSelecionadoQuant = '';
      setTimeout(() => { $('#input_' + this.idSelecionadoPreco).focus().select(); }, 400)
    }
  }

  clickedMe() {
    if (!this.matMenuTimer) return;
  }

  onKeydown(event, tipo, codProduto) {
    var novoValor = event.target.value;
    const itemIndex = codProduto && this.pedido.itens.findIndex(e => e.codigo_produto_cliente === codProduto);

    if (tipo == 'cond') {
      this.pedido.prazo_pagamento = novoValor;
      return;
    }

    if (tipo == 'escape') {
      this.idSelecionadoPreco = '';
      this.idSelecionadoQuant = '';
    }

    if (tipo == 'quant' && itemIndex !== -1) {
      this.pedido.itens[itemIndex].quant_pedido_confirmada = parseInt(novoValor);
      this.idSelecionadoQuant = this.pedido.itens.find(e => tipo + '_' + e.id_pedido + e.codigo_produto_cliente == this.idSelecionadoQuant).id_pedido;
    }

    if (tipo == 'preco' && itemIndex !== -1) {
      this.pedido.itens[itemIndex].preco_venda_confirmada = parseFloat(novoValor);
      this.idSelecionadoPreco = this.pedido.itens.find(e => tipo + '_' + e.id_pedido + e.codigo_produto_cliente == this.idSelecionadoPreco).id_pedido;
    }

    this.quantidadeTotal = 0;
    this.valorPedidoTotal = 0;
    this.pedido.itens.map(e => {
      this.quantidadeTotal += e.quant_pedido_confirmada;
      this.valorPedidoTotal += ((e.preco_venda_confirmada ? e.preco_venda_confirmada : e.preco_venda_confirmada) * (e.quant_pedido_confirmada));
    });

    this.valorPedidoTotalStr = this.valorPedidoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  }

  salvarPedido() {
    this.ativaLoading++;
    return this.apiAprovaPed.setPedidos(this.pedido.id_pedido, this.pedido, 'S').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: 'Pedido Salvo'
      });

      this.carregaDados();
    }).finally(() => {
      this.ativaLoading--;
    });
  }

  pedidoDivergente(): boolean {
    return this.precoDivergente() || this.quantidadeDivergente();
  }

  itemPrecoDivergente(item: IItens): boolean {
    const condSelecionada = this.condicoesPagamento.find(e => String(e.codplpag) === this.pedido.prazo_pagamento);

    if (condSelecionada && condSelecionada.is_negociado) {
      return false
    }

    return item.preco_tabela > item.preco_venda_confirmada && 0 !== item.quant_pedido_confirmada;
  }

  itemQuantidadeDivergente(item: IItens): boolean {
    return item.quant_pedido_confirmada % item.multiplo_fornecedor !== 0;
  }

  precoDivergente(): boolean {
    if (!this.pedido.itens) {
      return false;
    }

    return this.pedido.itens.some((item) => this.itemPrecoDivergente(item));
  }

  quantidadeDivergente(): boolean {
    if (!this.pedido.itens) {
      return false;
    }

    return this.pedido.itens.some((item) => this.itemQuantidadeDivergente(item));
  }

  possivelAlterarPedido(): boolean {
    return this.pedido.status_pedido === 'P';
  }

  temPrecoDiferente(): boolean {
    return this.pedido.itens.some((item) => item.preco_tabela < item.preco_venda_confirmada);
  }

  ajustarPrecos() {
    this.Modal.fire({
      title: 'Tem certeza?',
      text: "Deseja ajustar os preços de venda que estiverem acima do preço de tabela?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, ajustar!',
      cancelButtonText: 'Não, cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.pedido.itens.forEach((item) => {
          if (item.preco_venda_confirmada > item.preco_tabela) {
            item.preco_venda_confirmada = item.preco_tabela;
          }
        });
        this.Toast.fire({
          icon: 'success',
          title: 'Preços ajustados com sucesso!'
        });
      }
    });
  }
}
